// mxc-panel-class is used to override the default dialog styles coming from msp-fe-libs, so we can use Chameleon UI modal.
// This class will eventually be removed once we remove the dependency on msp-fe-libs.

.mxc-panel-class .mat-dialog-container {
  padding: 0px;
  background: transparent;
  box-shadow: none;
  color: inherit;
  border-radius: 0px;
  overflow: visible;
}

.mxc-panel-class .mat-mdc-dialog-container [mxc-modal] {
  overflow: auto;
  max-height: 100vh;
}

@media (max-width: 768px) {
  .mxc-panel-class {
    width: 100vw !important;
    max-width: 100vw !important;
  }

  .mxc-panel-class .mat-mdc-dialog-container {
    border-radius: 0;
  }
}

// Mat snack bar chameleon UI overrides
// Remove this after we implement our own snack bar design

.mat-snack-bar-container.legacy-snackbar {
  @apply mxc-bg-semantic-bg-neutral-emphasisplus mxc-text-semantic-fg-neutral-onemphasis-default mxc-shadow-elevation-lg;
}

// Style to fix the additional right border on material notched outline. Can be removed after we remove all Angular Material form controls.
// https://github.com/tailwindlabs/tailwindcss/discussions/9993

.mdc-notched-outline__notch {
  border-right: none !important;
}

:root {
  --mdc-filled-button-label-text-size: 16px;
  --mdc-outlined-button-label-text-size: 16px;
  --mdc-protected-button-label-text-size: 16px;
  --mdc-text-button-label-text-size: 16px;
  --mdc-filled-button-label-text-font: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  --mdc-outlined-button-label-text-font: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  --mdc-protected-button-label-text-font: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  --mdc-outlined-text-field-outline-color: #e0e0e0;
  --mdc-outlined-button-label-text-color: #57586e;
  --mdc-text-button-label-text-color: #57586e;
  --mat-dialog-content-padding: 0px 0px;
  --mdc-plain-tooltip-container-color: #575f65;
  --mdc-plain-tooltip-supporting-text-font: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  --mdc-plain-tooltip-supporting-text-line-height: 1.125rem;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-tracking: 0.026875rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  .mdc-form-field {
    --mdc-form-field-label-text-size: 0.875rem;
  }
  .mat-mdc-slide-toggle {
    --mdc-form-field-label-text-color: var(--mxc-color-Semantic-fg-neutral-subtle-default);
    --mdc-form-field-label-text-size: 0.875rem;
  }
  .mat-mdc-tab-link {
    --mat-tab-header-label-text-tracking: 0.026875rem;
  }
  .mat-mdc-button {
    --mdc-text-button-label-text-font: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
    --mdc-text-button-label-text-tracking: 0.026875rem;
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
  color: var(--mxc-color-Semantic-fg-neutral-subtle-default) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--mxc-color-Semantic-fg-neutral-emphasis) !important;
}

.type-primary {
  .mdc-button__label {
    color: var(--mxc-color-Semantic-bg-brand-emphasis-default);
  }
}

.mdc-card {
  padding: 16px;
}

.mat-drawer-content {
  background-color: #f6f6fa;
}

.container,
.container-fluid,
.container-xl,
.container-lg {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.mat-mdc-form-field {
  width: 100%;
  margin: 0 0 1rem 0;
}

.mat-mdc-form-field-subscript-wrapper {
  margin-top: 0.4rem;
}

.mat-mdc-form-field-error {
  font-size: 0.875rem;
}

.mat-grouped-form {
  display: flex;
  align-items: flex-start;
  span {
    display: block;
  }
  msp-button {
    .mdc-button {
      border-radius: 0 5px 5px 0;
      height: 56px;
      padding: 0 2.5rem 0 0.625rem;
    }
  }
  .mat-mdc-form-field {
    display: inline-block;
    width: auto;
    .mdc-notched-outline {
      .mdc-notched-outline__leading {
        border-radius: 0;
      }
      .mdc-notched-outline__trailing {
        border-radius: 0;
      }
    }
    &:first-of-type {
      .mdc-notched-outline {
        .mdc-notched-outline__leading {
          border-radius: 5px 0 0 5px;
        }
      }
    }
  }
}

msp-button {
  display: inline-block;

  & + msp-button {
    margin-left: 1.25rem;
  }

  &.button-full {
    button {
      width: 100%;
    }
  }

  &.button-icon {
    button {
      min-width: 0;
      padding: 0;
      height: 2.25rem;
      width: 2.25rem;
    }
  }

  &.button-icon-round {
    button {
      border-radius: 50%;
      min-width: 0;
      padding: 0;
      height: 2.25rem;
      width: 2.25rem;
    }
  }

  .mdc-button__label {
    display: flex;
    align-items: center;
    letter-spacing: normal;

    .d-flex {
      align-items: center;
    }

    msp-icon + span {
      margin-left: 0.625rem;
    }
  }
  .mat-mdc-unelevated-button[disabled],
  .mat-mdc-unelevated-button.mat-mdc-button-disabled {
    background-color: var(--mxc-color-Semantic-bg-brand-emphasis-default);
    color: #fff;
    opacity: 0.5;
  }
}

.mat-mdc-text-field-wrapper {
  background-color: white;
}

.mat-sidenav-container-primary {
  background-color: #f6f6fa;
}

.modal-dialog {
  position: relative;
  .close-button {
    position: absolute;
    top: -20px;
    right: -20px;
  }
}
.mat-mdc-dialog-container {
  .mat-mdc-dialog-content {
    -webkit-overflow-scrolling: touch;
    overflow: visible;
  }
}

.mdc-tooltip {
  padding: 0.625rem;
  max-width: 9.8125rem;
  border-radius: 4px;
  .mdc-tooltip__surface {
    text-align: left;
  }
}

.mat-mdc-radio-group {
  .mdc-form-field {
    align-items: start;
  }
  .mat-mdc-radio-button .mdc-radio {
    padding: 3px 8px 0 0;
  }
  .mdc-label {
    white-space: normal;
  }
  &.mat-radio-button-top {
    mat-radio-button .mdc-label {
      align-items: start;
    }
  }
}

msp-button.input-clear-button button {
  padding: 0;
  font-size: 0.875rem;
  min-width: 36px;
  margin-right: 0.625rem;
}

msp-button.close-button button {
  padding: 0;
  font-size: 0.875rem;
  min-width: 36px;
  margin-right: 0.625rem;
}

.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
  flex-grow: 0 !important;
}

.mxc-panel-class .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: var(--mxc-border-radius-lg, 4px);
}
